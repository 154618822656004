<template>
    <SimpleTable formclass="File/form" endpoint="files" :aditionalColumns="headers" :forceReloadOnChange="true" keepOpenFormOnSave="false">
        <template>
            <h1>Arquivos</h1>
            <v-divider class="mb-4" />
        </template>
    </SimpleTable>
</template>

<script>
import SimpleTable from "@/components/core/SimpleTable";
export default {
    components: { SimpleTable },

    data() {
        return {
            headers: [
                { text: this.$t("Habilitado"), value: "enable", align: "right", sortable: false },
                { text: this.$t("Código"), value: "key", align: "right", sortable: true },
            ],
        };
    },
};
</script>